<script>
import pagination from "@/mixins/pagination";
import paymentService from "@/services/payments";
import Spinner from "@/components/ui/Spinner";
import helperService from "@/services/helper";
import ModalPaymentFileAttach from "./ModalPaymentFileAttach";
import ModalPayment from "./ModalPayment";
import ModalCsv from "./ModalCsv";
import { mapGetters } from "vuex";
import { s3Url } from "@/services/config";

export default {
  name: "PaymentList",

  mixins: [pagination],

  components: {
    Spinner,
    ModalPaymentFileAttach,
    ModalPayment,
    ModalCsv,
  },

  data() {
    return {
      showDialogModal: false,
      isLoading: false,
      payments: [],
      selectedPayments: [],
      selectedPayment: undefined,
      modal: undefined,
      modalType: undefined,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "isPatient", "user"]),
  },

  created() {
    this.getPayments();

    this.$bus.$on("payment-update", (payment) => {
      console.log(payment);
    });
  },

  methods: {
    getPayments() {
      this.isLoading = true;

      let query = {
        ...(this.isDoctor && { user: this.user._id }),
        ...(this.isPatient && { patient: this.user._id }),
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        populate: [
          {
            path: "user",
            select: "firstName lastName",
          },
          {
            path: "patient",
            select: "firstName lastName",
          },
          {
            path: "service",
            populate: ["medicalSpecialty"],
          },
        ],
      };

      paymentService
        .get(query)
        .then((res) => {
          this.payments = res.docs;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getServiceTypeLabel(payment) {
      if (!payment.service) {
        return "Sin servicio";
      }
      switch (payment.serviceModel) {
        case "Appointment":
          return `Consulta ${
            payment.service?.attentionType === "virtual"
              ? "virtual"
              : "presencial"
          }`;

        default:
          return "Otro";
      }
    },

    getPaymentStatus(payment) {
      switch (payment.status) {
        case "pending":
          return "Pendiente";
        case "expired":
          return "Expirado";
        case "completed":
          return "Cobrado";
        case "refunded":
          return "Reembolsado";
        default:
          return "N/A";
      }
    },

    getServiceStatusLabel(payment) {
      switch (payment.serviceModel) {
        case "Appointment":
          switch (payment.service?.status) {
            case "pending":
              return "Pendiente";
            case "started":
              return "Iniciado";
            case "done":
              return "Completado";
            case "cancelled":
              return "Cancelado";
            default:
              return "Otro";
          }

        default:
          return "Otro";
      }
    },

    getPaymentInvoiceLabel(payment) {
      switch (payment.invoiceStatus) {
        case "pending":
          return "Pendiente de cargar";

        case "not-applicable":
          return "No corresponde";

        case "sent":
          return "Enviada";

        case "accepted":
          return "Aceptada";

        case "declined":
          return "Rechazada";

        default:
          return "Otro";
      }
    },

    getPaymentBillingLabel(payment) {
      switch (payment.billing.status) {
        case "pending":
          return this.isDoctor ? "Pendiente de cobrar" : "Pendiente de pagar";

        case "paid":
          return this.isDoctor ? "Cobrada" : "Pagada";

        case "not-applicable":
          return "No corresponde";

        default:
          return "Otro";
      }
    },

    handleSelectedPayment(added, id) {
      if (added) {
        this.selectedPayments.push(id);
      } else {
        this.selectedPayments = this.selectedPayments.filter(
          (elem) => elem !== id
        );
      }
    },

    showModal({ modal, modalType, selectedPayment }) {
      this.modal = modal;
      this.modalType = modalType;
      this.selectedPayment = selectedPayment;
      this.showDialogModal = true;
    },

    closeModal() {
      this.modal = undefined;
      this.modalType = undefined;
      this.selectedPayment = undefined;
      this.showDialogModal = false;
    },

    paymentUpdated(updatedPayment) {
      const index = this.payments.findIndex(
        (payment) => payment._id === updatedPayment._id
      );

      this.payments[index] = {
        ...this.payments[index],
        invoiceStatus: updatedPayment.invoiceStatus,
        billing: updatedPayment.billing,
      };
    },

    attachFile(id, file) {
      console.log(id, file);
    },

    getPaymentCommission(payment) {
      if (
        payment?.commission?.percentage &&
        payment?.amount &&
        payment?.currency
      ) {
        return `${payment.currency} ${
          (payment.commission.percentage / 100) * payment.amount
        }`;
      }
      return "N/A";
    },

    getFinalAmount(payment) {
      if (
        payment?.commission?.percentage &&
        payment?.amount &&
        payment?.currency
      ) {
        return `${payment.currency} ${
          payment.amount -
          (payment.commission.percentage / 100) * payment.amount
        }`;
      }
      return "N/A";
    },

    downloadFiles(files) {
      if (files && files.length) {
        files.forEach((file) => {
          const link = document.createElement("a");
          link.target = "_blanc";
          link.href = s3Url + "/" + file.key;
          link.download = file.key;
          link.click();
        });
      }
    },

    getServiceMedicalSpecialtyName(payment) {
      return payment.service?.medicalSpecialty?.name;
    },
  },
};
</script>

<template lang="pug">
  section.user-list
    header.headline
      //- .headline__title
        h1
          | Usuarios
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        .left
        .right
          button.button.button.button--blue(@click="showModal({modal: 'csv'})")
            span.button__text Descargar CSV

          el-dropdown(trigger="click")
            button.button.button.button--blue(
              @click.stop
              :disabled="!selectedPayments.length"
            )
              span.button__text Acciones

            el-dropdown-menu(slot="dropdown")
              el-dropdown-item(
                :disabled="!selectedPayments.length"
              )
                div(@click="showModal({modal: 'attach-file', modalType: 'other'})")
                  span.button__text Adjuntar documentación

              //- el-dropdown-item(
              //-   :disabled="!selectedPayments.length"
              //- )
              //-   div(@click="showModal({modal: 'attach-file', modalType: 'invoice'})")
              //-     span.button__text Adjuntar factura


    .box
      .box__content--stretch
        spinner(v-if="isLoading")
        h2.no-payments(v-if="!isLoading && !payments.length") No hay resultados
        table(v-else)
          thead
            tr
              th
              th #
              th Fecha
              th(v-if="!isDoctor") {{ isPatient ? "Doctor" : "Prestador" }}
              th Especialidad
              th(v-if="!isPatient") Paciente
              th Servicio
              th Estado
              th(v-if="!isDoctor") Precio
              th(v-if="isAdmin") Estado
                br
                | cobro
              th(v-if="isAdmin") Esquema
                br
                | comisión
              th(v-if="isAdmin") Importe
                br
                | cobrado
              th(v-if="isAdmin") Comisión
              th(v-if="!isPatient") Honorario
                br
                | profesional
              //- th Estado
              //-   br
              //-   | factura
              th(v-if="!isPatient") Estado
                br
                | {{isAdmin ? "pago" : "cobro"}}
              th
          tbody
            tr(v-for="(payment, index) in payments" :key="payment._id")
              td
                el-checkbox(@change="(added) => handleSelectedPayment(added, payment._id)")
              td {{ payment.friendlyId || 'N/A' }}
              td {{ payment.createdAt | formatDate }}
              td(v-if="!isDoctor") {{ getFullName(payment.user) }}
              td {{ getServiceMedicalSpecialtyName(payment) }}
              td(v-if="!isPatient") {{ getFullName(payment.patient) }}
              td {{ getServiceTypeLabel(payment) }}
              td {{ getServiceStatusLabel(payment) }}
              td(v-if="!isDoctor") {{ payment.currency }} {{ payment.amount }}
              td(v-if="isAdmin") {{ getPaymentStatus(payment) }}
              td(v-if="isAdmin") {{ payment.commission ? `${payment.commission.name} (${payment.commission.percentage}%)` : 'N/A' }}
              td(v-if="isAdmin") {{ payment.currency }} {{ payment.amount }}
              td(v-if="isAdmin") {{ getPaymentCommission(payment) }}
              td(v-if="!isPatient") {{ getFinalAmount(payment) }}
              //- td {{ getPaymentInvoiceLabel(payment) }}
              td(v-if="!isPatient") {{ getPaymentBillingLabel(payment) }}
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      el-dropdown-item(v-if="isAdmin")
                        div(@click="showModal({modal: 'payment-update', selectedPayment: payment})")
                          micon(name="edit")
                          | Editar
                      //- el-dropdown-item(:disabled="!payment.invoiceFiles || !payment.invoiceFiles.length")
                      //-   div(@click="downloadFiles(payment.invoiceFiles)")
                      //-     micon(name="attach_file")
                      //-     | Descargar factura
                      el-dropdown-item(:disabled="!payment.attachedFiles || !payment.attachedFiles.length")
                        div(@click="downloadFiles(payment.attachedFiles)")
                          micon(name="attach_file")
                          | Descargar documentación

      modal-payment(
        v-if="modal === 'payment-update'"
        :showDialogModal="showDialogModal",
        :selected-payment="selectedPayment"
        @payment-updated="paymentUpdated"
        @close="closeModal"
      )

      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )

    modal-payment-file-attach(
      v-if="modal === 'attach-file'",
      :showDialogModal="showDialogModal",
      :paymentIds="selectedPayments"
      :type="modalType"
      @close="closeModal"
    )
    modal-csv(
      v-if="modal === 'csv'",
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
</template>

<style lang="scss">
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

.actions-container:hover {
  cursor: pointer;
}

th label,
td label {
  margin: 0;
}

.user-list {
  .no-payments {
    text-align: center;
    padding: 50px;
    font-size: 2em;
    width: 100%;
  }

  .headline {
    .headline__title {
      @include media(desktop-up) {
        margin: 0 15px 15px 0;
      }
      @include media(web-up) {
        margin: 0 15px 0 0;
      }
    }
    .headline__actions {
      @include media(mobile-up) {
        width: 100%;
      }
      @include media(web-up) {
        width: auto;
      }

      .el-checkbox-group {
        justify-content: space-between;
        margin: 0;

        > * {
          width: calc(50% - 7.5px);
          margin: 0 0 10px;
        }

        @include media(mobile-up) {
          width: 100%;
        }
        @include media(tablet-up) {
          width: auto;
        }
        @include media(ipad-up) {
          align-items: center;
          > * {
            width: auto;
            margin: 0 10px 0 0;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
