<script>
import paymentService from "@/services/payments";

export default {
  name: "ModalCsv",

  data() {
    return {
      isLoading: false,
      start: undefined,
      end: undefined,
    };
  },

  props: {
    showDialogModal: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    close() {
      this.isLoading = false;
      this.$emit("close");
    },

    async getPaymentsCsv() {
      const params = {
        createdAt: {
          $gte: this.start,
          $lte: this.end,
        },
      };

      try {
        const response = await paymentService.getCsv(params);

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payments.csv");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.log(err);
      } finally {
        this.close();
      }
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-payment", :visible.sync="showDialogModal", :before-close="close", title="Descargar informe")
    .el-dialog__body
        label.label * Desde
        ValidationProvider(name="Desde" rules="required" v-slot="{ errors }")
          el-date-picker(v-model="start")
          span.has-error {{errors[0]}}
        label.label * Hasta
        ValidationProvider(name="Hasta" rules="required" v-slot="{ errors }")
          el-date-picker(v-model="end")
          span.has-error {{errors[0]}}
    .el-dialog__footer
      el-button(type="primary" :loading="isLoading" @click="getPaymentsCsv") Descargar
</template>
