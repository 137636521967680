<script>
import pagination from "@/mixins/pagination";

import debounce from "debounce";
import userService from "@/services/user";
import inviteService from "@/services/invite";
import helperService from "@/services/helper";
import segmentService from "@/services/segment";
import { USER_ROLES } from "@/services/constants";

import Spinner from "@/components/ui/Spinner";

const DEBOUNCE_MS_TIMEOUT = 500;
const { DOCTOR, MANAGER, ADMIN, ASSISTANT, SCORES } = USER_ROLES;

export default {
  name: "UserList",

  mixins: [pagination],

  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: false,
      searchInput: "",
      users: [],
      filterRoleIds: [],
    };
  },

  computed: {
    loggedUserRole() {
      return this.$store.state.user.role;
    },

    loggedUserId() {
      return this.$store.state.user._id;
    },

    usedRoles() {
      if (this.loggedUserRole === MANAGER.name) {
        return [DOCTOR, MANAGER];
      }

      if (this.loggedUserRole === ADMIN.name) {
        return [DOCTOR, MANAGER, ADMIN, ASSISTANT, SCORES];
      }

      return [];
    },
  },

  created() {
    this.filterRoleIds = this.usedRoles.map((r) => r.id);
    this.getUsers();

    this.$bus.$on("user-update", (user) => {
      const index = this.users.findIndex((u) => u._id === user._id);
      if (index >= 0) {
        this.$set(this.users, index, user);
      } else {
        this.users.push(user);
      }
    });
  },

  methods: {
    getUsers() {
      this.isLoading = true;

      let defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        roles: this.filterRoleIds,
      };

      //This is used when you make a search or change pages
      if (this.searchInput) {
        defaultQuery = { ...defaultQuery, searchInput: this.searchInput };
        defaultQuery = helperService.buildQuery(defaultQuery);
      }

      const searchQuery = defaultQuery;

      userService
        .get(searchQuery)
        .then((res) => {
          this.users = res.docs;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    debounceInput: debounce(function () {
      this.getUsers();
    }, DEBOUNCE_MS_TIMEOUT),

    editUser(id) {
      helperService.callIfNoSelectedText(() =>
        this.$router.push({
          name: "update-user",
          params: { id },
        })
      );
    },

    lockUser(user, index) {
      this.$confirm(
        `¿Desea bloquear al usuario/a: ${helperService.getFullName(user)}?`,
        "Bloquear",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          segmentService.track({ name: "User Locked" });
          userService.lock(user._id).then(() => {
            this.$set(this.users, index, {
              ...this.users[index],
              lockedAt: new Date(),
            });
          });
        })
        .catch(() => {});
    },

    unlockUser(id, index) {
      segmentService.track({ name: "User Unlocked" });
      userService.unlock(id).then(() => {
        this.$set(this.users, index, {
          ...this.users[index],
          lockedAt: undefined,
        });
      });
    },

    isLocked(user) {
      return user.lockedAt;
    },

    deleteUser(user) {
      this.$confirm(
        `¿Desea eliminar al usuario/a: ${helperService.getFullName(user)}?`,
        "Bloquear",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          segmentService.track({ name: "User Deleted" });
          return userService.delete(user._id);
        })
        .then(() => this.getUsers())
        .catch(() => {});
    },

    reSendInvite(email) {
      inviteService
        .sendInvite(email)
        .then(() =>
          this.$message.success(`Se reenvío una invitación a ${email}`)
        );
    },

    getUserRoleName(roleID) {
      const roleKey = Object.keys(USER_ROLES).find(
        (key) => USER_ROLES[key].id === roleID
      );
      return roleKey && USER_ROLES[roleKey].name;
    },

    onPageChange() {
      this.getUsers();
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },
  },
};
</script>

<template lang="pug">
  section.user-list
    header.headline
      //- .headline__title
        h1
          | Usuarios
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        .left
          el-checkbox-group(v-model="filterRoleIds")
            el-checkbox(
              v-for="role in usedRoles"
              v-if="usedRoles.length > 1"
              v-auth="`${role.key}.query`"
              @change='debounceInput'
              :key="role.id"
              :label="role.id"
            ) {{ role.alias || role.name }}
          el-input.search__input(
            prefix-icon="el-icon-search"
            @input="debounceInput"
            v-model="searchInput"
            placeholder="Buscar"
            :clearable="true"
          )
        .right
          router-link.button.button--blue(:to="{name: 'create-user'}" v-auth="['doctor.create', 'manager.create', 'assistan.create', 'admin.create']")
            micon(name="add_circle_outline").button__icon
            span.button__text Nuevo Usuario
    .box
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th Email
              th Telefono
              th Ciudad
              th Rol
              th Estado
              th Acciones
          tbody
            spinner(v-if="isLoading")
            tr(v-else v-for="(user, index) in users" :key="user._id" @click="editUser(user._id)")
              td {{ getFullName(user) }}
              td {{ user.email }}
              td {{ user.phone }}
              td {{ user.city }}
              td {{ getUserRoleName(user.role) }}
              td(v-if='isLocked(user)') 
                micon(name="lock")
              td(v-else) 
                micon(name="lock_open")
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      el-dropdown-item(v-auth="['doctor.update', 'manager.update', 'assistan.update', 'admin.update']")
                        div(@click="editUser(user._id)")
                          micon(name="edit")
                          | Editar usuario
                      el-dropdown-item(v-if="(loggedUserId !== user._id) && !user.lockedAt" v-auth="['doctor.update', 'manager.update', 'assistan.update', 'admin.update']")
                        div(@click="lockUser(user, index)")
                          micon(name="lock")
                          | Bloquear usuario
                      el-dropdown-item(v-if="loggedUserId !== user._id && user.lockedAt" v-auth="['doctor.update', 'manager.update', 'assistan.update', 'admin.update']")
                        div(@click="unlockUser(user._id, index)")
                          micon(name="lock_open")
                          | Desbloquear usuario
                      //el-dropdown-item(v-if="loggedUserId !== user._id" v-auth="['doctor.remove', 'manager.remove', 'assistan.remove', 'admin.remove']")
                        div(@click="deleteUser(user)")
                          micon(name="delete_forever")
                          | Eliminar usuario
                      el-dropdown-item(v-if="!user.passwordSetAt" v-auth="['doctor.update', 'manager.update', 'assistan.update', 'admin.update']")
                        div(@click="reSendInvite(user.email)")
                          micon(name="email")
                          | Reenviar invitación

      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.user-list {
  .headline {
    .headline__title {
      @include media(desktop-up) {
        margin: 0 15px 15px 0;
      }
      @include media(web-up) {
        margin: 0 15px 0 0;
      }
    }
    .headline__actions {
      @include media(mobile-up) {
        width: 100%;
      }
      @include media(web-up) {
        width: auto;
      }

      .el-checkbox-group {
        justify-content: space-between;
        margin: 0;

        > * {
          width: calc(50% - 7.5px);
          margin: 0 0 10px;
        }

        @include media(mobile-up) {
          width: 100%;
        }
        @include media(tablet-up) {
          width: auto;
        }
        @include media(ipad-up) {
          align-items: center;
          > * {
            width: auto;
            margin: 0 10px 0 0;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
