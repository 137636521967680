<script>
import { isEmpty } from "lodash";
import { merge } from "lodash";
import userService from "@/services/user";
import companyService from "@/services/company";
import { USER_ROLES } from "@/services/constants";
import Spinner from "@/components/ui/Spinner";
import FileUpload from "@/components/ui/FileUpload";
import fileUploadConfigService from "@/services/fileupload-config";
import segmentService from "@/services/segment";
import institutionService from "@/services/institution";
import medicalSpecialtyService from "@/services/medical-specialty";
import UserAddressSection from "@/components/user/UserAddressSection";
import leave from "@/mixins/leave";
import { isObjectLike } from "lodash";
import resourceService from "@/services/resource";
import { theme } from "@/services/config";
import commissionService from "@/services/commission";
import { capitalize } from "@/services/helper";
import { DOC_TYPES, DOC_TYPE_DEFAULT, LANGUAGES } from "@/services/constants";

import { enableScores } from "@/services/config";
import medicalInsuranceService from "@/services/medical-insurance";

const { ADMIN, MANAGER, DOCTOR, ASSISTANT, SCORES, PATIENT } = USER_ROLES;
const CONFIG_TYPE = "users";

export default {
  name: "UserDetail",

  components: {
    Spinner,
    FileUpload,
    UserAddressSection,
  },

  mixins: [leave],

  data() {
    return {
      capitalize,
      languages: LANGUAGES,
      theme,
      docTypes: DOC_TYPES,
      commissions: undefined,
      isLoading: false,
      countries: undefined,
      cities: undefined,
      medicalInsurances: [],
      useScores: enableScores,
      companies: [],
      institutions: [],
      medicalSpecialties: [],
      USER_ROLES,
      configId: this.$route.params.id,
      user: {
        patient: null,
        governmentId: {
          type: DOC_TYPE_DEFAULT,
          number: "",
        },
        dashExtension: {
          nationalityCity: {
            name: "",
          },
          rfc: "",
          languages: [],
          notificationChannels: {
            high: "",
            medium: "",
            low: "",
          },
        },
        firstName: "",
        lastName: "",
        publicId: "",
        email: "",
        phone: "",
        doctor: {
          dashExtension: {
            degree: {
              name: "",
              degreeLevel: "",
              issuedBy: "",
              issuedAt: "",
            },
          },
          // medicalInsurance: undefined
        },
        role: DOCTOR.id,
        supervisor: null,
        address: {
          street: "",
          city: "",
          district: theme === "dash" ? undefined : "Buenos Aires",
          country: theme === "dash" ? undefined : "Argentina",
          postalCode: "",
          betweenStreets: ["", ""],
        },
      },
      files: [],
      supervisors: [],
      isUpdate: this.$route.name === "update-user",
      isFetchingUser: false,
      isPostingUser: false,
      isLoadingSupervisors: false,
      hasAvatar: false,
      hasSignature: false,
    };
  },

  computed: {
    roleOptions() {
      let roles = [DOCTOR, MANAGER];
      if (this.$store.state.user.role === ADMIN.name) {
        roles.push(ADMIN, ASSISTANT);

        if (enableScores) {
          roles.push(SCORES);
        }
      }

      if (this.isUpdate) {
        let userRole = this.getRoleName(this.user.role);
        // admins and assistants will only have permission to change to admin or assistant
        if (userRole === ADMIN.key || userRole === ASSISTANT.key) {
          roles = roles.filter((role) => {
            return role.key === ADMIN.key || role.key === ASSISTANT.key;
          });
        }
      }

      return roles;
    },

    isRoleChangeAllowed() {
      if (!this.isUpdate) {
        return true;
      }
      const userRole = this.getRoleName(this.user.role);
      if (
        userRole === DOCTOR.key ||
        userRole === MANAGER.key ||
        userRole === PATIENT.key
      ) {
        return false;
      }
      return true;
    },

    sectors() {
      if (!this.user.company) {
        return [];
      }

      const company = this.companies.find((c) => c._id === this.user.company);

      return company?.sectors;
    },

    uploadMediaAvatarEndpoint() {
      return fileUploadConfigService.getUploadAvatarEndpoint(
        CONFIG_TYPE,
        this.configId
      );
    },

    uploadMediaSignatureEndpoint() {
      return fileUploadConfigService.getUploadSignatureEndpoint(
        CONFIG_TYPE,
        this.configId
      );
    },
  },

  created() {
    this.getUser();
    this.getSupervisors();
    this.getInstitutions();
    this.getMedicalSpecialties();
    this.getCountries();
    this.getMedicalInsurances();
    this.getCities();
    this.getCommissions();

    if (this.useScores) {
      this.$set(this.user, "company", null);
      this.$set(this.user, "companySector", null);
      this.getCompanies();
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    async onSignatureChange(event) {
      const file = event.target.files[0];

      this.user.publicCertificate = file;
    },

    getRoleName(roleId) {
      for (const role in USER_ROLES) {
        if (USER_ROLES[role].id === roleId) {
          return USER_ROLES[role].key;
        }
      }
    },

    escape(event) {
      if (event.keyCode == 27) this.goToRoute("users-list");
    },

    getManagers() {
      userService.getManagers().then(({ docs }) => (this.suppervisors = docs));
    },

    getCompanies() {
      companyService.get().then(({ docs }) => (this.companies = docs));
    },

    submit() {
      this.createOrUpdateUser();
    },

    async createOrUpdateUser() {
      const eventName = this.isUpdate ? "User Updated" : "User Created";
      segmentService.track({ name: eventName });

      this.isPostingUser = true;
      this.showLeaveGuard = false;
      const serviceCall = this.isUpdate
        ? userService.update
        : userService.create;

      if (
        !this.user.doctor.institutions &&
        !this.user.doctor.medicalSpecialties &&
        !this.user.doctor.professionalId
      ) {
        delete this.user.doctor;
      } else {
        if (!this.user.doctor.institutions)
          delete this.user.doctor.institutions;
        if (!this.user.doctor.medicalSpecialties)
          delete this.user.doctor.medicalSpecialties;
        if (!this.user.doctor.professionalId)
          delete this.user.doctor.professionalId;
      }

      const formData = new FormData();
      for (const key in this.user) {
        if (!isEmpty(this.user[key]) || this.user[key] instanceof File) {
          if (
            isObjectLike(this.user[key]) &&
            !(this.user[key] instanceof File)
          ) {
            formData.append(key, JSON.stringify(this.user[key]));
          } else {
            formData.append(key, this.user[key]);
          }
        }
      }

      try {
        const { data } = await serviceCall(formData);
        this.$bus.$emit("user-update", data || this.user);

        this.configId = this.configId || data._id;

        if (this.hasAvatar || this.hasSignature) {
          this.$refs.avatarUpload.startUpload();
          this.$refs.signatureUpload.startUpload();
        } else {
          this.goToRoute("users-list");
        }
      } finally {
        this.isPostingUser = false;
      }
    },

    getUser() {
      if (this.isUpdate) {
        this.isFetchingUser = true;
        userService
          .getById(this.$route.params.id)
          .then((user) => {
            this.user = merge({}, this.user, user);
          })
          .finally(() => {
            this.isFetchingUser = false;
          });
      }
    },

    getSupervisors() {
      this.isLoadingSupervisors = true;
      userService
        .get({ roles: [MANAGER.id] })
        .then((res) => {
          this.supervisors = res.docs;
        })
        .finally(() => (this.isLoadingSupervisors = false));
    },

    async getMedicalSpecialties() {
      this.isLoading = true;

      medicalSpecialtyService
        .getMedicalSpecialties()
        .then((res) => {
          this.medicalSpecialties = res;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getInstitutions() {
      this.isLoading = true;

      institutionService
        .getInstitutions()
        .then((res) => {
          this.institutions = res;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onInputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
      }
    },

    getModalName() {
      if (this.isUpdate) {
        return "Actualizar Usuario";
      }
      return "Nuevo Usuario";
    },

    goToRoute(name) {
      this.$router.push({ name });
    },

    handleUploadFiles() {
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    getAvatar() {
      return this.user.avatar && this.user.avatar.key ? [this.user.avatar] : [];
    },

    getSignature() {
      return this.user?.doctor?.signature && this.user.doctor.signature.key
        ? [this.user.doctor.signature]
        : [];
    },

    getAvatarDropdownOptions() {
      return {
        method: "put",
        paramName: "avatar",
        maxFiles: 1,
      };
    },

    getSignatureDropdownOptions() {
      return {
        method: "put",
        paramName: "signature",
        maxFiles: 1,
      };
    },

    onFileUploadSuccess() {
      const avatarCompleted = this.$refs.avatarUpload
        ? this.$refs.avatarUpload.$refs.uploader.dropzone.files.every(
            (file) => file.status === "success" || file.manuallyAdded
          )
        : true;

      const signatureCompleted = this.$refs.signatureUpload
        ? this.$refs.signatureUpload.$refs.uploader.dropzone.files.every(
            (file) => file.status === "success" || file.manuallyAdded
          )
        : true;

      if (avatarCompleted && signatureCompleted) {
        this.goToRoute("users-list");
      }
    },

    onUploadFilesError() {
      this.isPostingUser = false;
    },

    onAvatarAdded() {
      this.hasAvatar = true;
    },

    onAvatarRemoved() {
      this.hasAvatar = false;
      this.user.avatar = null;
    },

    onSignatureAdded() {
      this.hasSignature = true;
    },

    onSignatureRemoved() {
      this.hasSignature = false;
      this.user.doctor.signature = null;
    },

    async getCountries() {
      this.isLoading = true;
      try {
        const countries = await resourceService.getCountries();
        this.countries = countries;
      } finally {
        this.isLoading = false;
      }
    },

    async getMedicalInsurances() {
      this.isLoading = true;
      try {
        const medicalInsurances = await medicalInsuranceService.get();
        this.medicalInsurances = medicalInsurances.docs;
      } finally {
        this.isLoading = false;
      }
    },

    async getCities() {
      this.isLoading = true;
      try {
        const cities = await resourceService.getCities();
        this.cities = cities;
      } finally {
        this.isLoading = false;
      }
    },

    async getCommissions() {
      this.isLoading = true;
      try {
        const { docs } = await commissionService.getCommissions();
        this.commissions = docs;
      } finally {
        this.isLoading = false;
      }
    },
  },

  watch: {
    "user.doctor.medicalInsurance"() {
      if (!this.user.doctor.medicalInsurance) {
        delete this.user.doctor.medicalInsurance;
      }
    },
  },
};
</script>

<template lang="pug">
ValidationObserver(v-slot="{handleSubmit}")
  form.modal(@submit.prevent="handleSubmit(submit)")
    header.modal__header
      h2.modal__title {{ getModalName() }}
      .modal__actions
        el-button(type="info" @click="goToRoute('users-list')") Cancelar
        el-button.border(type="primary" native-type="submit" :loading="isPostingUser") Guardar
    p.modal__subtitle Los campos con (*) son obligatorios
    .modal__content(v-if="isFetchingUser")
      spinner
    .modal__content(v-else)
      // Personal Info Section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="person")
            h3.sign__title Información Personal
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label * Nombre
                ValidationProvider(name="Nombre", rules="required", v-slot="{ errors }")
                  el-input(v-model="user.firstName", autofocus)
                  span.has-error {{ errors[0] }}
            .modal__row
              fieldset.modal__field
                label.label * Apellido
                ValidationProvider(name="Apellido", rules="required", v-slot="{ errors }")
                  el-input(v-model="user.lastName")
                  span.has-error {{ errors[0] }}
              fieldset.modal__field(v-if="theme === 'dash'")
                label.label Segundo Apellido
                el-input(v-model="user.secondLastName")
            .modal__row
              fieldset.modal__field
                label.label ID Proveedor
                el-input(v-model="user.publicId")
            .modal__row(v-if='theme === "dash"')
              fieldset.modal__field
                label.label * Nacionalidad
                ValidationProvider(name="nationality" rules="required" v-slot="{errors}")
                  el-select(v-model="user.nationality" placeholder="" filterable clearable default-first-option)
                    el-option(
                      v-for="country in countries"
                      :key="country.value"
                      :label="capitalize(country.name)"
                      :value="country.name"
                    )
                  span.has-error {{errors[0]}}
              fieldset.modal__field(v-if='theme === "dash"')
                label.label * Lugar de Nacimiento
                ValidationProvider(name="nationalityCity" rules="required" v-slot="{errors}")
                  el-select(v-model="user.dashExtension.nationalityCity.name" placeholder="" name="nationalityCity" filterable)
                    el-option(
                      v-for="city in cities"
                      :key="city.value"
                      :label="capitalize(city.name)"
                      :value="city.name"
                    )
                  span.has-error {{errors[0]}}
            .modal__row(v-if='theme === "dash"')
              fieldset.modal__field
                label.label * Tipo de Documento
                el-select(v-model="user.governmentId.type.toUpperCase()" :disabled="isUpdate" placeholder="" default-first-option filterable)
                  el-option(v-for="docType in docTypes" :key="docType" :value="docType" :label="docType.toUpperCase()")
              fieldset.modal__field
                label.label * Número de Documento
                ValidationProvider(name="número de documento" rules="required" v-slot="{errors}")
                  el-input(v-model="user.governmentId.number")
                  span.has-error {{errors[0]}}
              fieldset.modal__field
                label.label * RFC
                el-input(v-model="user.dashExtension.rfc")
            .modal__row(v-if='theme === "dash"')
              fieldset.modal__field
                label.label Idiomas
                el-select(v-model="user.dashExtension.languages" multiple allow-create)
                  el-option(
                    :key="language"
                    v-for="language in languages"
                    :value="language"
                    :label="language"
                  )
              
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Foto
                file-upload(
                  ref="avatarUpload",
                  type="image"
                  :url="uploadMediaAvatarEndpoint",
                  :files="getAvatar()",
                  :dropzone-options="getAvatarDropdownOptions()"
                  @file-added="onAvatarAdded",
                  @files-removed="onAvatarRemoved",
                  @fileupload-success="onFileUploadSuccess"
                  @fileupload-error="onUploadFilesError"
                )
      // Contact Section
      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="forum")
            h3.sign__title Contacto
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label * Email
                ValidationProvider(name="Email", rules="required|email", v-slot="{ errors }")
                  el-input(type="email", v-model="user.email", :disabled="isUpdate")
                  span.has-error {{ errors[0] }}
              fieldset.modal__field
                label.label Teléfono
                el-input(v-model="user.phone", name="Teléfono")
              fieldset.modal__field
                label.label WhatsApp
                el-input(v-model="user.secondaryPhone", name="Teléfono")
            .modal__row
              fieldset.modal__field
                label.label Medio notificación bajo
                el-select(v-model="user.dashExtension.notificationChannels.low")
                  el-option(key="phone" value="phone" label="Teléfono")
                  el-option(key="secondaryPhone" value="secondaryPhone" label="WhatsApp")
                  el-option(key="email" value="email" label="Email")
              fieldset.modal__field
                label.label Medio notificación medio
                el-select(v-model="user.dashExtension.notificationChannels.medium")
                  el-option(key="phone" value="phone" label="Teléfono")
                  el-option(key="secondaryPhone" value="secondaryPhone" label="WhatsApp")
                  el-option(key="email" value="email" label="Email")
              fieldset.modal__field
                label.label Medio notificación alto
                el-select(v-model="user.dashExtension.notificationChannels.high")
                  el-option(key="phone" value="phone" label="Teléfono")
                  el-option(key="secondaryPhone" value="secondaryPhone" label="WhatsApp")
                  el-option(key="email" value="email" label="Email")


      .modal__block
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="lock")
            h3.sign__title Seguridad
          article.modal__fields
            .modal__row
              fieldset.modal__field.role-options
                label.label * Rol
                el-select(v-model="user.role" required :disabled="!isRoleChangeAllowed")
                  el-option(
                    :key="role.key"
                    v-for="role in roleOptions"
                    v-auth="`${role.key}.create`"
                    :value="role.id"
                    :label="role.name"
                  )

              fieldset.modal__field(v-if="user.role === USER_ROLES.DOCTOR.id || user.role._id === USER_ROLES.DOCTOR.id")
                label.label Médico coordinador
                el-select(
                  v-model="user.supervisor"
                  :disabled="isLoadingSupervisors"
                )
                  el-option(
                    v-for="supervisor in supervisors"
                    :key="supervisor.id"
                    :value="supervisor.id"
                    :label="`${supervisor.lastName} ${supervisor.firstName}`"
                  )
              
              fieldset.modal__field(v-if="theme === 'dash' && user.role === USER_ROLES.DOCTOR.id || user.role._id === USER_ROLES.DOCTOR.id")
                label.label * Comisión
                ValidationProvider(name="Comisión", rules="required", v-slot="{ errors }")
                  el-select(
                    v-model="user.doctor.commission"
                    :disabled="isLoadingSupervisors"
                    required
                  )
                    el-option(
                      v-if="commissions && commissions.length"
                      v-for="commission in commissions"
                      :key="commission._id"
                      :value="commission._id"
                      :label="`${commission.name} (${commission.percentage}%)`"
                    )
                  span.has-error {{errors[0]}}

      .modal__block(v-if="user.doctor && user.role === USER_ROLES.DOCTOR.id || user.role._id === USER_ROLES.DOCTOR.id")
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="institution")
            h3.sign__title Instituciones
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label Instituciones
                el-select(v-model="user.doctor.institutions" placeholder="Instituciones" filterable multiple clearable default-first-option)
                  el-option(
                    v-for="i in institutions"
                    :key="i._id"
                    :label="i.name"
                    :value="i._id"
                  )
              fieldset.modal__field
                label.label Seguro médico
                el-select(v-model="user.doctor.medicalInsurance" placeholder="Seguro médico" clearable)
                  el-option(
                    v-for="medicalInsurance in medicalInsurances"
                    :key="medicalInsurance._id"
                    :label="medicalInsurance.name"
                    :value="medicalInsurance._id"
                  )
      .modal__block(v-if="user.doctor && user.role === USER_ROLES.DOCTOR.id || user.role._id === USER_ROLES.DOCTOR.id")
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="medical-specialty")
              h3.sign__title Especialidades médicas
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label Matrícula
                  ValidationProvider(name="Matrícula", :rules="`min:6|max:8${theme === 'dash' ? '|required': ''}`", v-slot="{ errors }")
                    el-input(v-model="user.doctor.professionalId")
                    span.has-error {{errors[0]}}

                fieldset.modal__field
                  label.label Especialidades médicas
                  el-select(v-model="user.doctor.medicalSpecialties" placeholder="Especialidades médicas" filterable multiple clearable default-first-option)
                    el-option(
                      v-for="m in medicalSpecialties"
                      :key="m._id"
                      :label="m.name"
                      :value="m._id"
                    )

      .modal__block(v-if='theme === "dash"')
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="medical-specialty")
              h3.sign__title Grado académico
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label Nombre
                  el-input(v-model="user.doctor.dashExtension.degree.name")
                fieldset.modal__field
                  label.label Grado de estudio
                  el-input(v-model="user.doctor.dashExtension.degree.degreeLevel")
              .modal__row
                fieldset.modal__field
                  label.label Institución
                  el-input(v-model="user.doctor.dashExtension.degree.issuedBy")
                fieldset.modal__field
                  label.label Finalizado en
                  el-date-picker(lang="es" v-model="user.doctor.dashExtension.degree.issuedAt")

      //- Address section
      user-address-section(v-if="cities && cities.length" v-model="user" :cities="cities" type="user")

      .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="medical-specialty")
              h3.sign__title Firma
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label Certificado público
                  ValidationProvider(name="Certificado" rules="" v-slot="{ errors }")
                    el-input(type="file" v-model="user.publicCertificate")
                    span.has-error {{errors[0]}}
              .modal__row
                fieldset.modal__field
                  label.label Firma digital
                  file-upload(
                    ref="signatureUpload",
                    type="image"
                    :url="uploadMediaSignatureEndpoint",
                    :files="getSignature()",
                    :dropzone-options="getSignatureDropdownOptions()"
                    @file-added="onSignatureAdded",
                    @files-removed="onSignatureRemoved",
                    @fileupload-success="onFileUploadSuccess"
                    @fileupload-error="onUploadFilesError"
                  )

      // Company and Sector section
      .modal__block(v-show="useScores && user.role === USER_ROLES.SCORES.id")
        .modal__section
          .modal__sign.sign
            .sign__icon
              micon(name="videogame")
            h3.sign__title Scoring
          article.modal__fields
            .modal__row
              fieldset.modal__field
                label.label * Empresa
                el-select(v-model="user.company" placeholder="" filterable clearable default-first-option)
                  el-option(
                    v-for="c in companies"
                    :key="c._id"
                    :label="c.name"
                    :value="c._id"
                  )
</template>

<style lang="scss" scoped>
.role-options {
  max-width: 50%;
}
</style>
