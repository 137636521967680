<script>
import FileUpload from "@/components/ui/FileUpload";
import fileUploadConfigService from "@/services/fileupload-config";

export default {
  name: "ModalPaymentFileAttach",

  data() {
    return {
      isLoading: false,
      hasFiles: false,
    };
  },

  props: {
    paymentIds: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    FileUpload,
  },

  computed: {
    uploadMediaEndpoint() {
      return fileUploadConfigService.getPaymentFilesEndpoint(
        this.paymentIds,
        this.type
      );
    },
  },

  methods: {
    close() {
      this.isLoading = false;
      this.$emit("close");
    },

    onFileAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
    },

    handleUploadFiles() {
      this.isLoading;
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    getDropdownOptions() {
      return {
        method: "put",
        uploadMultiple: true,
        maxFiles: 3,
        maxFilesize: 5,
        acceptedFiles: "image/*,application/pdf",
      };
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-payment-file-attach", :visible.sync="showDialogModal", :before-close="close", title="Adjuntar archivos")
    .el-dialog__body
        file-upload(
          ref="fileUpload",
          :url="uploadMediaEndpoint",
          :dropzone-options="getDropdownOptions()"
          @file-added="onFileAdded",
          @files-removed="onFileRemoved",
          @queue-complete="close"
        )
    .el-dialog__footer
      el-button(type="primary" :loading="isLoading" @click="handleUploadFiles" :disabled="false") Adjuntar
</template>
