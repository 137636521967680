<script>
import paymentService from "@/services/payments";

export default {
  name: "ModalPayment",

  data() {
    return {
      isLoading: false,
      payment: { ...this.selectedPayment },
    };
  },

  props: {
    selectedPayment: {
      type: Object,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    close() {
      this.isLoading = false;
      this.$emit("close");
    },

    updatePayment() {
      this.isLoading = true;

      paymentService
        .updatePayment(this.payment)
        .then((payment) => {
          this.$emit("payment-updated", payment);
        })
        .catch(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
          this.close();
        });
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-payment", :visible.sync="showDialogModal", :before-close="close", title="Actualizar pago")
    .el-dialog__body
        //- label.label * Estado factura
        //-     ValidationProvider(name="Estado" rules="required" v-slot="{ errors }")
        //-         el-select(v-model="payment.invoiceStatus")
        //-             el-option(value="pending" label='Pendiente de cargar')
        //-             el-option(value="accepted" label='Aceptada')
        //-             el-option(value="not-applicable" label='No corresponde')
        //-             el-option(value="declined" label='Rechazada')
        //-         span.has-error {{errors[0]}}
        label.label * Estado pago
        ValidationProvider(name="Estado" rules="required" v-slot="{ errors }")
          el-select(v-model="payment.billing.status")
            //- el-option(value="sent" label='Cargada' disabled)
            el-option(value="pending" label='Pendiente de pagar')
            el-option(value="paid" label='Pagada')
            el-option(value="not-applicable" label='No corresponde')
          span.has-error {{errors[0]}}
        label.label * Fecha de pago
        ValidationProvider(name="Fecha de pago" v-slot="{ errors }")
          el-date-picker(v-model="payment.billing.paidAt" :disabled="payment.billing.status !== 'paid'")
          span.has-error {{errors[0]}}
        label.label * Observaciones
        ValidationProvider(name="Observaciones" v-slot="{ errors }")
          el-input(v-model="payment.billing.observation" :disabled="payment.billing.status !== 'paid'")
          span.has-error {{errors[0]}}
    .el-dialog__footer
      el-button(type="primary" :loading="isLoading" @click="updatePayment") Guardar
</template>
